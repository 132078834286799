import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, TemplateRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { FormInputWrapperModule } from '@app/shared/modules/form/form-input-wrapper.component';
import { FormInputDirective } from '@app/shared/modules/form/form-input.component';

import { FormInputModule } from '../form-input.component';
import { customValueAccessorFactory } from '../value-accessor.componet';

@Component({
  selector: 'app-text-input',
  template: `
    <app-form-input-wrapper
      [label]="label"
      [error]="$any(error$ | async)"
      [status]="status$ | async"
    >
      <nz-input-group
        *ngIf="prefix || suffix; else onlyInput"
        [nzPrefix]="prefix"
        [nzSuffix]="suffix"
        [nzSize]="'large'"
      >
        <ng-container *ngTemplateOutlet="input"></ng-container>
      </nz-input-group>

      <ng-template #onlyInput>
        <ng-container *ngTemplateOutlet="input"></ng-container>
      </ng-template>

      <ng-template #input>
        <ng-container *ngIf="textarea; else inputTmpl">
          <textarea
            nz-input
            [placeholder]="placeholder"
            [nzSize]="'large'"
            [disabled]="this.disabled"
            [readonly]="this.readonly"
            [rows]="textAreaRows"
            [nzStatus]="$any(status$ | async)"
            [(ngModel)]="value"
            (ngModelChange)="change(value)"
          ></textarea>
        </ng-container>
        <ng-template #inputTmpl>
          <input
            nz-input
            [type]="inputType"
            [nzSize]="'large'"
            [placeholder]="placeholder"
            [disabled]="this.disabled"
            [readonly]="this.readonly"
            [nzStatus]="$any(status$ | async)"
            [(ngModel)]="value"
            (ngModelChange)="change(value)"
          />
        </ng-template>
      </ng-template>
    </app-form-input-wrapper>
  `,
  styles: [],
  styleUrls: ['text-input.component.less'],
  providers: [customValueAccessorFactory(TextInputComponent)]
})
export class TextInputComponent extends FormInputDirective<string> {
  @Input() public prefix?: TemplateRef<any> | string;
  @Input() public suffix?: TemplateRef<any> | string;
  @Input() public inputType = 'text';
  @Input() public disabled = false;
  @Input() public readonly = false;
  @Input() public textarea = false;
  @Input() public textAreaRows = 3;
}

@NgModule({
  declarations: [TextInputComponent],
  imports: [
    NzFormModule, //
    NzInputModule,
    FormsModule,
    CommonModule,
    NzIconModule,
    FormInputModule,
    ReactiveFormsModule,
    FormInputWrapperModule
  ],
  exports: [TextInputComponent]
})
export class TextInputModule {}
