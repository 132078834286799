import { Component, Input, NgModule, OnDestroy, ViewEncapsulation } from '@angular/core';
import { NzFormControlStatusType, NzFormModule } from 'ng-zorro-antd/form';
import { CommonModule } from '@angular/common';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-form-input-wrapper',
  template: `
    <nz-form-item>
      <nz-form-label *ngIf="label">{{ label }}</nz-form-label>
      <nz-form-control
        nzHasFeedback
        [nzValidateStatus]="status ?? ''"
        [nzErrorTip]="error ?? ''"
        [nzValidatingTip]="'Validating'"
      >
        <ng-content></ng-content>
      </nz-form-control>
    </nz-form-item>
  `,
  encapsulation: ViewEncapsulation.None
})
export class FormInputWrapperComponent<T> implements OnDestroy {
  @Input() public label: string;
  @Input() public status: NzFormControlStatusType | null;
  @Input() public error: string | null;

  private _destroy$ = new Subject<void>();

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}

@NgModule({
  imports: [NzFormModule, CommonModule],
  exports: [FormInputWrapperComponent],
  declarations: [FormInputWrapperComponent],
  providers: []
})
export class FormInputWrapperModule {}
