import { Injectable, NgModule } from '@angular/core';
import {NzMessageDataOptions, NzMessageModule, NzMessageRef, NzMessageService} from 'ng-zorro-antd/message';

@Injectable()
export class MessageService {
  constructor(private msg: NzMessageService) {}

  success(msg: string, opt?: NzMessageDataOptions) {
    this.msg.success(msg, opt);
  }

  error(msg: string, opt?: NzMessageDataOptions) {
    this.msg.error(msg, opt);
  }

  info(msg: string, opt?: NzMessageDataOptions) {
    this.msg.info(msg, opt);
  }

  loading(msg: string, opt?: NzMessageDataOptions) {
    return this.msg.loading(msg, opt);
  }

  close(msg: NzMessageRef) {
    this.msg.remove(msg.messageId);
  }
}

@NgModule({
  providers: [MessageService],
  imports: [NzMessageModule]
})
export class MessageServiceModule {}
