import { AfterContentInit, Directive, Injector, Input, NgModule, OnDestroy } from '@angular/core';
import { NzFormControlStatusType, NzFormModule } from 'ng-zorro-antd/form';
import { CommonModule } from '@angular/common';
import { FormControlStatus } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { ValueAccessorDirective } from '@app/shared/modules/form/value-accessor.directive';
import { map } from 'rxjs/operators';

@Directive({
  selector: '[appFormInputWrapper]'
})
export class FormInputDirective<T> extends ValueAccessorDirective<T> implements OnDestroy, AfterContentInit {
  @Input() public label: string;
  @Input() public note: string;
  @Input() public placeholder: string;
  @Input() public messageTips?: Record<string, any>;

  public status$: Observable<NzFormControlStatusType>;
  public error$: Subject<string> = new Subject<string>();

  protected _destroy$ = new Subject<void>();

  constructor(inj: Injector) {
    super(inj);
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public override ngAfterContentInit() {
    super.ngAfterContentInit();
    if (!this.formControl) return;
    this.status$ = this.formControl.statusChanges.pipe(
      map<FormControlStatus, NzFormControlStatusType>((status) => {
        switch (status) {
          case 'INVALID':
            this.emitError();
            return 'error';
          case 'PENDING':
            return 'validating';
          default:
            return 'success';
        }
      })
    );
  }

  private emitError() {
    const errorKeys = Object.entries(this.formControl?.errors ?? {})
      .filter(([, val]) => !!val)
      .map(([key]) => key);

    const errorKey = errorKeys.find((key) => this.messageTips?.[key]);
    if (!errorKey) {
      this.error$.next(errorKeys?.[0] ?? '');
      return;
    }

    this.error$.next(this.messageTips?.[errorKey] ?? errorKey);
  }
}

@NgModule({
  imports: [NzFormModule, CommonModule],
  exports: [FormInputDirective],
  declarations: [FormInputDirective],
  providers: []
})
export class FormInputModule {}
